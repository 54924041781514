<template>
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.2664 9.97998C19.2664 9.35498 19.1882 8.88623 19.1101 8.37842H9.89136V11.6987H15.3601C15.1648 13.144 13.7195 15.8784 9.89136 15.8784C6.57104 15.8784 3.87573 13.144 3.87573 9.74561C3.87573 4.31592 10.282 1.81592 13.7195 5.13623L16.3757 2.59717C14.696 1.03467 12.4695 0.0581055 9.89136 0.0581055C4.50073 0.0581055 0.203857 4.39404 0.203857 9.74561C0.203857 15.1362 4.50073 19.4331 9.89136 19.4331C15.4773 19.4331 19.2664 15.5269 19.2664 9.97998Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconGoogle'
}
</script>
